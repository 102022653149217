<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :title="title"
    :visible.sync="visible"
    center
    top="5vh"
    width="60%"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto" size="small">
      <el-row>
        <!--评分项-->
        <el-col :span="12">
          <el-form-item :label="$t('page.ScoringItem')" prop="ratingItem">
            <el-select
              v-model="form.ratingItem"
              :disabled="isEdit"
              :placeholder="$t('page.inputPlaceholder')"
              allow-create
              clearable
              default-first-option
              filterable
            >
              <el-option
                v-for="item in rulesList"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <!-- 评分方式-->
        <el-col v-if="isEdit" :span="12">
          <el-form-item :label="$t('page.ScoringMethod')">
            <el-input :value="ruleTypeMap[form.ruleType]" disabled />
          </el-form-item>
        </el-col>
        <!--评分子项-->
        <el-col :span="24">
          <el-form-item :label="$t('page.ScoringSubitem')" prop="ratingSubitem">
            <el-input
              v-model="form.ratingSubitem"
              :autosize="{minRows:3}"
              :placeholder="$t('page.inputPlaceholder')"
              maxlength="2000"
              show-word-limit
              type="textarea"
            />
          </el-form-item>
        </el-col>
        <!--加分数-->
        <el-col v-if="type === 'AddItem' || +form.ruleType === +ruleTypeEnum['加分项']" :span="24">
          <el-form-item :label="$t('page.AddScore')">
            <el-descriptions :column="3" :content-style="{'text-align': 'center'}" :label-style="{'text-align': 'center'}" border direction="vertical">
              <!--Problem加分-->
              <el-descriptions-item :label="$t('page.ProblemAddScoring')">
                /
              </el-descriptions-item>
              <!--Suggestion加分-->
              <el-descriptions-item :label="$t('page.SuggestionAddScoring')">
                /
              </el-descriptions-item>
              <!--Excellent加分-->
              <el-descriptions-item :label="$t('page.ExcellentAddScoring')">
                <el-input
                  v-model.number="form.scoreExcellent"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  @change="(val) => handleInputNumber(val, 'scoreExcellent', 1)"
                />
              </el-descriptions-item>
            </el-descriptions>
          </el-form-item>
        </el-col>
        <!--减分数-->
        <el-col v-else-if="type === 'AddSubtractItem' || +form.ruleType === +ruleTypeEnum['减分项']" :span="24">
          <el-form-item :label="$t('page.MinusScore')">
            <el-descriptions :column="4" :content-style="{'text-align': 'center'}" :label-style="{'text-align': 'center'}" border direction="vertical">
              <!--Problem减分-->
              <el-descriptions-item :label="$t('page.ProblemMinusScoring')">
                <el-input
                  v-model.number="form.scoreProblem"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  @change="(val) => handleInputNumber(val, 'scoreProblem')"
                />
              </el-descriptions-item>
              <!--Suggestion减分-->
              <el-descriptions-item :label="$t('page.SuggestionMinusScoring')">
                <el-input
                  v-model.number="form.scoreSuggestion"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  @change="(val) => handleInputNumber(val, 'scoreSuggestion')"
                />
              </el-descriptions-item>
              <!--Excellent减分-->
              <el-descriptions-item :label="$t('page.ExcellentMinusScoring')">
                <el-input
                  v-model.number="form.scoreExcellent"
                  :placeholder="$t('page.inputPlaceholder')"
                  clearable
                  disabled
                  @change="(val) => handleInputNumber(val, 'scoreExcellent')"
                />
              </el-descriptions-item>
              <!--是否严重减分项-->
              <el-descriptions-item :label="$t('page.isSeriousPenalty')">
                <el-radio-group v-model="form.isSerious">
                  <el-radio v-for="item in isSeriousList" :key="item.value" :label="+item.value">
                    {{ item.label }}
                  </el-radio>
                </el-radio-group>
              </el-descriptions-item>
            </el-descriptions>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template #footer>
      <el-button @click="handleClose">
        {{ $t('order.Cancel') }}
      </el-button>
      <el-button type="primary" @click="handleSubmit">
        {{ $t('page.sure') }}
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { isSeriousList, ruleTypeEnum, ruleTypeMap } from './const'
import { addScoringRules, getAllRules } from './apis/AddDialog'
import { updateScoringRules } from './apis/list'
import { isEmpty } from 'lodash'

export default {
  name: 'AddDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      rulesList: [],
      form: {
        ratingItem: '',
        ratingSubitem: '',
        scoreProblem: '',
        scoreSuggestion: '',
        scoreExcellent: 0,
        ruleType: '',
        isSerious: 0
      },
      rules: {
        ratingItem: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'change' },
          { max: 2000, message: this.$t('page.inputPlaceholder'), trigger: 'change' }
        ],
        ratingSubitem: [
          { required: true, message: this.$t('page.inputPlaceholder'), trigger: 'change' }
        ]
      }
    }
  },
  computed: {
    isEdit() {
      return this.type === 'EditScoringItem'
    },
    title() {
      return this.$t(`page.${this.type}`)
    },
    submitFn() {
      return this.isEdit ? updateScoringRules : addScoringRules
    },
    ruleTypeEnum() {
      return ruleTypeEnum
    },
    ruleTypeMap() {
      return ruleTypeMap
    },
    isSeriousList() {
      return isSeriousList
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.getRulesList()
        if (this.type === 'EditScoringItem') {
          this.form = {
            ...this.row
          }
        } else if (this.type === 'AddItem') {
          this.form.scoreExcellent = 1
        }
      }
    }
  },
  methods: {
    async getRulesList() {
      const { datas } = await getAllRules()
      this.rulesList = datas || []
    },
    handleClose() {
      this.form = {
        ratingItem: '',
        ratingSubitem: '',
        scoreProblem: '',
        scoreSuggestion: '',
        scoreExcellent: 0,
        isSerious: 0
      }
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
    },
    handleInputNumber(value, field, min = 0) {
      // 将输入的非数字字符替换为空
      const number = value.replace(/\D/g, '')
      if (number === '') {
        this.form[field] = ''
        return
      }
      // 最大100
      if (number > 100) {
        this.form[field] = 100
        return
      }
      // 最小
      if (number < min) {
        this.form[field] = min
        return
      }
      this.form[field] = number
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let ruleType = this.form.ruleType
          if (this.type === 'AddItem') {
            ruleType = ruleTypeEnum['加分项']
          } else if (this.type === 'AddSubtractItem') {
            if (isEmpty(this.form.scoreSuggestion) || isEmpty(this.form.scoreProblem)) {
              this.$message.warning('请填写减分数')
              return
            }
            ruleType = ruleTypeEnum['减分项']
          }
          this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
            type: 'warning',
            closeOnClickModal: false,
            beforeClose: async(action, instance, done) => {
              if (action === 'confirm') {
                instance.cancelButtonLoading = instance.confirmButtonLoading = true
                const { msg } = await this.submitFn({
                  ...this.form,
                  ruleType
                }).finally(() => {
                  instance.cancelButtonLoading = instance.confirmButtonLoading = false
                })
                this.$message.success(msg)
                done()
                this.$emit('refresh')
                this.handleClose()
              } else {
                done()
              }
            }
          }).catch(() => {})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

