import { generateListByMap } from '@/utils'

export const statusMap = {
  0: '启用',
  1: '停用'
}

export const { list: statusList, enum: statusEnum } = generateListByMap(statusMap)

export const ruleTypeMap = {
  0: '减分项',
  1: '加分项'
}

export const { list: ruleTypeList, enum: ruleTypeEnum } = generateListByMap(ruleTypeMap)

export const isSeriousMap = {
  0: '否',
  1: '是'
}

export const { list: isSeriousList, enum: isSeriousEnum } = generateListByMap(isSeriousMap)
