import request from '@/utils/request'

export function addScoringRules(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/insertQuestionnaireRule`,
    method: 'post',
    data
  })
}

export function getAllRules() {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQuestionnaireRuleItem`,
    method: 'get'
  })
}
