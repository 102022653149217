<template>
  <div>
    <el-form inline size="small">
      <!--评分方式-->
      <el-form-item :label="$t('page.ScoringMethod')">
        <el-select
          v-model="form.ruleType"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in ruleTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!--状态-->
      <el-form-item :label="$t('page.status')">
        <el-select
          v-model="form.delFlag"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in statusList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <!--是否严重扣分项-->
      <el-form-item :label="$t('page.isSeriousPenalty')">
        <el-select
          v-model="form.isSerious"
          :placeholder="$t('page.selectPlaceholder')"
          clearable
          filterable
        >
          <el-option
            v-for="item in isSeriousList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button @click="handleReset">{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <vxe-toolbar>
      <template #buttons>
        <!--新增加分项-->
        <el-button type="primary" @click="handleOpenAddDialog('AddItem', null)">
          {{ $t('page.AddItem') }}
        </el-button>
        <!--新增减分项-->
        <el-button type="primary" @click="handleOpenAddDialog('AddSubtractItem', null)">
          {{ $t('page.AddSubtractItem') }}
        </el-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      ref="table"
      :data="tableData"
      :loading="queryLoading"
      :max-height="maxHeight"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: false }"
      :show-overflow="false"
      align="center"
      border
      resizable
    >
      <!--评分项-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('page.ScoringItem')" field="ratingItem" />
      <!--评分子项-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('page.ScoringSubitem')" field="ratingSubitem" width="200" />
      <!--评分方式-->
      <vxe-column :title="$t('page.ScoringMethod')" field="ruleType">
        <template #default="{row}">
          {{ ruleTypeMap[row.ruleType] || '/' }}
        </template>
      </vxe-column>
      <!--Problem计分-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('page.ProblemScoring')" field="scoreProblem" />
      <!--Suggestion计分-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('page.SuggestionScoring')" field="scoreSuggestion" />
      <!--Excellent计分-->
      <vxe-column :formatter="emptyCellFormatter" :title="$t('page.ExcellentScoring')" field="scoreExcellent" />
      <!--严重减分项-->
      <vxe-column :title="$t('page.SeriousPenalty')" field="isSerious">
        <template #default="{row}">
          {{ isSeriousMap[row.isSerious] || '/' }}
        </template>
      </vxe-column>
      <!--状态-->
      <vxe-column :title="$t('page.status')" field="delFlag">
        <template #default="{row}">
          {{ statusMap[row.delFlag] || '/' }}
        </template>
      </vxe-column>
      <vxe-column :title="$t('page.operate')">
        <template #default="{row}">
          <!--启用-->
          <el-button
            v-if="+row.delFlag === +statusEnum['停用']"
            type="text"
            @click="handleEditStatus(row, statusEnum['启用'])"
          >
            {{ $t('page.enabled') }}
          </el-button>
          <el-button
            v-if="+row.delFlag === +statusEnum['启用']"
            type="text"
            @click="handleEditStatus(row, statusEnum['停用'])"
          >
            {{ $t('page.disable') }}
          </el-button>
          <el-button type="text" @click="handleOpenAddDialog('EditScoringItem', row)">
            {{ $t('page.modify') }}
          </el-button>
        </template>
      </vxe-column>
    </vxe-table>
    <paging ref="pager" :pager="pager" end @update="handlePagerUpdate" />
    <add-dialog
      :row.sync="currentRow"
      :type="type"
      :visible.sync="addDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import { debounceGetTableMaxHeight } from '@/utils'
import Paging from '@/components/Paging/index.vue'
import { getScoringRulesList, updateScoringRules } from './apis/list'
import { isSeriousList, isSeriousMap, ruleTypeList, ruleTypeMap, statusEnum, statusList, statusMap } from './const'
import AddDialog from './AddDialog.vue'

export default {
  name: 'ScoreRulesList',
  components: {
    Paging,
    AddDialog
  },
  data() {
    return {
      form: {
        delFlag: '',
        isSerious: '',
        ruleType: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      maxHeight: 0,
      tableData: [],
      addDialogVisible: false,
      currentRow: null,
      type: ''
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form,
        current: this.pager.current,
        size: this.pager.size
      }
    },
    isSeriousMap() {
      return isSeriousMap
    },
    isSeriousList() {
      return isSeriousList
    },
    ruleTypeList() {
      return ruleTypeList
    },
    ruleTypeMap() {
      return ruleTypeMap
    },
    statusList() {
      return statusList
    },
    statusMap() {
      return statusMap
    },
    statusEnum() {
      return statusEnum
    }
  },
  watch: {
    form: {
      handler() {
        this.queryChange = true
      },
      deep: true
    }
  },
  created() {
    this.handleQuery()
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    emptyCellFormatter({ row, column }) {
      return row[column.property] || '/'
    },
    async handleQuery() {
      this.queryLoading = true
      if (this.controller) {
        this.controller.abort()
        setTimeout(() => {
          this.queryLoading = true
        })
      }
      this.controller = new AbortController()
      const signal = this.controller.signal
      if (this.queryChange) {
        this.pager.current = 1
      }
      const { datas } = await getScoringRulesList(this.queryParams, signal).finally(() => {
        this.queryLoading = false
      })
      this.tableData = datas?.records || []
      this.pager.total = datas?.pager?.total || 0
    },
    handleReset() {
      this.form = {
        delFlag: '',
        isSerious: '',
        ruleType: ''
      }
      this.handleQuery()
    },
    handleOpenAddDialog(type, row) {
      this.type = type
      this.currentRow = row
      this.addDialogVisible = true
    },
    handleEditStatus(row, status) {
      this.$confirm(this.$t('title.batchprocessing'), this.$t('page.Prompt'), {
        closeOnClickModal: false,
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = instance.cancelButtonLoading = true
            const { msg } = await updateScoringRules({
              ...row,
              delFlag: status
            }).finally(() => {
              instance.confirmButtonLoading = instance.cancelButtonLoading = false
            })
            this.$message.success(msg)
            done()
            await this.handleQuery()
          } else {
            done()
          }
        }
      })
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
