import request from '@/utils/request'

export function getScoringRulesList(params, signal) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/getQualityRulesByPage`,
    method: 'get',
    params,
    signal
  })
}

export function updateScoringRules(data) {
  return request({
    url: `${process.env.VUE_APP_ERP_NEW_API}mos-web-core/Customer/updateQuestionnaireRule`,
    method: 'post',
    data
  })
}
